export const PERMISSIONS = {
    // HIGHER_EXEC: ["CEO", "EXECUTIVE_STAFF", "SSLG_STAFF", "ATTORNEY", "REMOTE"],
    HIGHER_EXEC_LEVEL_1: ["CEO", "EXECUTIVE_STAFF"],
    HIGHER_EXEC_LEVEL_2: ["CEO", "EXECUTIVE_STAFF", "SSLG_STAFF"],
    HIGHER_EXEC_LEVEL_3: ["CEO", "EXECUTIVE_STAFF", "SSLG_STAFF", "ATTORNEY"],
    HIGHER_EXEC_LEVEL_4: ["CEO", "EXECUTIVE_STAFF", "SSLG_STAFF", "ATTORNEY", "REMOTE"],
    HIGHER_EXEC_LEVEL_5: ["CEO", "EXECUTIVE_STAFF", "SSLG_STAFF", "ATTORNEY", "REMOTE", "CLIENT"],
    HIGHER_EXEC_LEVEL_6: ["CEO", "EXECUTIVE_STAFF", "SSLG_STAFF", "REMOTE"],
    LOWER_EXEC: ["CEO", "EXECUTIVE_STAFF", 'SSLG_STAFF', "ATTORNEY", "REMOTE", "CLIENT"],
    LOWER_EXEC_LEVEL_1: ['CEO','EXECUTIVE_STAFF', 'ADVISOR'],
    LOWER_EXEC_LEVEL_2: ['CEO','EXECUTIVE_STAFF', 'SSLG_STAFF', "ATTORNEY", "REMOTE", 'ADVISOR'],
    LOWER_EXEC_LEVEL_3: ['CEO','EXECUTIVE_STAFF', 'SSLG_STAFF', "ATTORNEY", "REMOTE", 'ADJUSTER', 'CLIENT'],
    LOWER_EXEC_LEVEL_4: ['CEO','EXECUTIVE_STAFF', 'SSLG_STAFF', "ATTORNEY", "REMOTE", 'ADJUSTER'],

    STAFF_ROLES: ["CEO", "EXECUTIVE_STAFF", "SSLG_STAFF", "ATTORNEY", "REMOTE"]
  };
